// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tag-js": () => import("/Users/dwhite/website/web/src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-blog-post-js": () => import("/Users/dwhite/website/web/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-about-me-js": () => import("/Users/dwhite/website/web/src/pages/aboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-all-js": () => import("/Users/dwhite/website/web/src/pages/all.js" /* webpackChunkName: "component---src-pages-all-js" */),
  "component---src-pages-index-js": () => import("/Users/dwhite/website/web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myfiles-js": () => import("/Users/dwhite/website/web/src/pages/myfiles.js" /* webpackChunkName: "component---src-pages-myfiles-js" */),
  "component---src-pages-resume-js": () => import("/Users/dwhite/website/web/src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-work-js": () => import("/Users/dwhite/website/web/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/dwhite/website/web/.cache/data.json")

