import React from 'react';
import Sidebar from '../components/sidebar';
import Content from '../components/content';
import Helmet from 'react-helmet'
import styles from '../styles/container.module.css';
import Transition from '../components/transition'

export default ({children, location, pageContext}) => {
    //For rendering special pages, like the map page
    if (pageContext.layout === "special") {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daniel White</title>
                    <link rel="canonical" href="https://white-daniel.com" />
                </Helmet>
                {children}
            </div> 
        )
    }
    //For rendering normal pages
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Daniel White</title>
                <link rel="canonical" href="https://white-daniel.com" />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className={styles.sidebarLeft + " col-md-3 p-0"}>
                        <Sidebar />
                    </div>
                    <div className={styles.content + " col-md-6 px-0 py-3"}>
                        <Transition location={location}>
                            <Content>
                                {children}
                            </Content>
                        </Transition>
                    </div>
                    <div className={styles.sidebarRight + " col-md-3 p-0"}>
                    </div>
                </div>
            </div>
        </div>
    );
};