import React from 'react';
import styles from "../styles/content.module.css"

export default ({children}) => {

    return (
    <div className={styles.contentContainer}>
       {children}
    </div>
    )

}
        