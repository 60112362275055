import { Link } from 'gatsby'
import React from 'react'
import styles from "../styles/navlink.module.css"


const activeStyles = {
    textDecoration: 'underline',
    color: 'rgb(255, 255, 255)'
}

export default ({children, to}) => (
    <Link to={to} className={styles.link + " text-light"} activeStyle={activeStyles}>
        {children}
    </Link>
)

