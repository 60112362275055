import React from 'react';
import logo from '../assets/images/logo.png';
import styles from "../styles/sidebar.module.css"
import Navlink from './navlink'
import { Nav }from 'react-bootstrap'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faGithubSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'

library.add(faLinkedin);
library.add(faGithubSquare);
library.add(faEnvelopeSquare);
library.add(faFilePdf);

export default () => (
    <div className={styles.sidebar + " top"}>
        <div className="flex-container">
            <div className="text-center">
                <a href="/">
                    <img src={logo} className="img-fluid" width="200px" alt="Logo"/>
                </a>
            </div>
            <hr className="bg-light mx-5" />
            <Nav className="flex-column mx-5">
                <Navlink to="/">Blog</Navlink>
                <Navlink to="/aboutMe">About</Navlink>
                <Navlink to="/work">Projects</Navlink>
            </Nav>
            <Nav className="flex-column pt-5 mx-5">
                <Navlink to="/resume">Resume</Navlink>
            </Nav>
            <hr className="bg-secondary mx-5" />
            <ul className={styles.iconsList + " text-center"}>
                <li className={styles.inlineListItem}>
                    <a href="https://www.linkedin.com/in/daniel-white-76434a66/">
                        <FontAwesomeIcon className={styles.contactIcon} icon={faLinkedin} size="lg"/>
                    </a>
                </li>
                <li className={styles.inlineListItem}>
                    <a href="mailto:whitedann@gmail.com">
                        <FontAwesomeIcon className={styles.contactIcon} icon={faEnvelopeSquare} size="lg"/>   
                    </a>
                </li>
                <li className={styles.inlineListItem}>
                    <a href="https://github.com/whitedann">
                        <FontAwesomeIcon className={styles.contactIcon} icon={faGithubSquare} size="lg"/>
                    </a>
                </li>
            </ul>
            <footer className="footer pt-3 text-center">
                <div className={styles.footerText + " text-light"}>
                    Copyright © Daniel White 2019
                </div>
            </footer>
        </div>
    </div>
)